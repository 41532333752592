import axios from "axios";
import cheerio from "cheerio";

const fetchLinkedInProfile = async (url) => {
  try {
    const response = await axios.get(url);
    const $ = cheerio.load(response.data);

    const profileData = {
      name: extractName($),
      headline: extractHeadline($),
      location: extractLocation($),
      about: extractAbout($),
      education: extractEducation(),
      experience: extractExperience($),
    };

    const profile = formatProfile(profileData);
    return profile;
  } catch (error) {
    console.error("Failed to fetch LinkedIn profile:", error);
  }
};

const extractName = ($) => $("h1.top-card-layout__title").text().trim();
const extractHeadline = ($) =>
  $("h2.top-card-layout__headline").text().trim().replaceAll(/\n/g, "").replaceAll(/\s+/g, " ");
const extractLocation = ($) => $("div.top-card__subline-item").text().trim();

const extractAbout = ($) => {
  return $(".core-section-container__content").eq(0).text().trim().replace(/\n/g, " ").replaceAll(/\s+/g, " ");
};

const extractEducation = () => {
  return "## UC San Diego\nMaster's degree | Computer Science\n2023 - 2025 \n \n## University of Birmingham\nBachelor of Science | Artificial Intelligence and Computer Science\n2023 - 2025";
};

const extractExperience = ($) => {
  let i = 0;
  let experience = "";

  while ($(".experience-item__location").eq(i).text().trim().replace(/\n/g, " ") !== "") {
    const exp = {
      company: $(".profile-section-card__subtitle").eq(i).text().trim().replace(/\n/g, " ").replaceAll(/\s+/g, " "),
      title: $(".profile-section-card__title").eq(i).text().trim().replace(/\n/g, " ").replaceAll(/\s+/g, " "),
      duration: $(".experience-item__duration")
        .eq(i)
        .text()
        .trim()
        .replace(/\n/g, " ")
        .replaceAll(/\s+/g, " ")
        .replace(/^(.{0,19}).*$/, "$1"),
      location: $(".experience-item__location").eq(i).text().trim().replace(/\n/g, " "),
      detail: $(".show-more-less-text__text--less")
        .eq(i)
        .text()
        .trim()
        .replace(/\n/g, " ")
        .replaceAll(/\s+/g, " ")
        .replace(/•/gm, "\n- ")
        .replace(/^\s+/, ""),
    };

    experience += formatExperience(exp);
    i = i + 1;
  }

  return experience.replace(/\n+$/, "").replace(/Show more/g, "\n[Show more](https://www.linkedin.com/in/jwjoel)");
};

const formatExperience = (exp) => {
  return `\n## ${exp.company}
### ${exp.title}
${exp.duration}
${exp.location}
${exp.detail}`;
};

const formatProfile = (profileData) => {
  return `Hello! I'm Joel (Jiawei) Wang.\n
# Location
${profileData.location}

# About
${profileData.about}

# Education
${profileData.education}

# Experiences
${profileData.experience}`;
};

const ResumeBuilder = {
  fetchLinkedInProfile,
};

export default ResumeBuilder;
