import FileSystem from "./FileSystem";
import axios from "axios";

const handleCDCommand = (commandArgs, fileSystem, currentDir, setCurrentDir) => {
  if (commandArgs.length === 0) {
    return "Usage: cd [directory]\n";
  }
  let newDir = commandArgs[0];

  newDir = FileSystem.parsePath(newDir, currentDir);

  const fileContent = FileSystem.getFileContent(newDir, fileSystem);
  if (fileContent !== null && fileContent.type === "directory") {
    setCurrentDir(newDir);
  } else {
    return "cd: " + newDir + ": No such file or directory\n";
  }
  return "";
};

const handleCATCommand = (commandArgs, fileSystem, currentDir) => {
  if (commandArgs.length === 0) {
    return "Usage: cat [file]\n";
  }
  let fileName = commandArgs[0];
  let filePath = FileSystem.parsePath(fileName, currentDir);
  let fileContent = FileSystem.getFileContent(filePath, fileSystem);
  if (fileContent && fileContent.type === "file") {
    return fileContent.content + "\n";
  } else {
    return "cat: " + fileName + ": No such file or directory";
  }
};

const handleLSCommand = (currentDir, fileSystem) => {
  const currentDirectory = FileSystem.getFileContent(currentDir, fileSystem);
  if (currentDirectory === null || currentDirectory.type !== "directory") {
    return "ls: " + currentDir + ": No such file or directory";
  }
  const fileList = Object.keys(currentDirectory.content || {});
  const longestNameLength = fileList.reduce((maxLength, fileName) => {
    return Math.max(maxLength, fileName.length);
  }, 0);
  const output = fileList
    .map((fileName) => {
      const fileType = currentDirectory.content[fileName].type;
      const isDirectory = fileType === "directory";
      const isScript = fileType === "script";
      const paddingLength = longestNameLength - fileName.length + 5;
      if(fileName.startsWith(".")) return "";
      else return `<span class="${isDirectory ? "directory" : ""} ${isScript ? "script" : ""}">${fileName}</span>` + " ".repeat(paddingLength);
    })
    .join("");
  //console.log(output);
  return output;
};

const handleSHCommand = (commandArgs, fileSystem, currentDir) => {
  if (commandArgs.length === 0) {
    return "Usage: sh [file]";
  }
  let fileName = commandArgs[0];
  let filePath = FileSystem.parsePath(fileName, currentDir);
  let fileContent = FileSystem.getFileContent(filePath, fileSystem);
  if (fileContent && fileContent.type === "script") {
    if (fileContent.operation === "redirect") {
      window.location.href = fileContent.content;
      return "Redirecting...";
    } else if (fileContent.operation === "get_request") {
      //TODO: fix await issue
      //const {data} = axios.request({url: 'https://api.ipify.org/?format=json' , method: 'get'});
      //console.log(JSON.stringify(data));
      return "This feature is not yet implemented\n";
    } else {
      return "This feature is not yet implemented\n";
    }
  } else {
    return "sh: " + fileName + ": No such file or directory\n";
  }
};

const handleCommand = (command, commandArgs, currentDir, fileSystem, setCurrentDir) => {
  let newOutput = "";

  if(command.startsWith("./")) {
    const splitCommand = command.split("./")
    if(splitCommand[1].length > 0) {
      newOutput = handleSHCommand([splitCommand[1]], fileSystem, currentDir) + "\n"
    } else {
      newOutput = command + ": premission denied\n";
    }
    return newOutput;
  }

  switch (command) {
    case "cd":
      newOutput = handleCDCommand(commandArgs, fileSystem, currentDir, setCurrentDir);
      break;
    case "ls":
      newOutput = handleLSCommand(currentDir, fileSystem)+ "\n";
      break;
    case "cat":
      newOutput = handleCATCommand(commandArgs, fileSystem, currentDir)+ "\n";
      break;
    case "sh":
      newOutput = handleSHCommand(commandArgs, fileSystem, currentDir)+ "\n";
      break;
    case "pwd":
      if (!currentDir.startsWith("/")) {
        newOutput = "/" + currentDir;
      } else {
        newOutput = currentDir+ "\n";
      }
      break;
    case "help":
      newOutput = `Available commands: \ncd [directory]    : Change the current directory \nls                : List files and directories in the current directory \ncat [file]        : Display the file content \nhelp              : Show this help message \nclear             : Clear the terminal screen\nsh [file]         : Run bash file`+ "\n";
      break;
    case "clear":
      newOutput = "";
      break;
    default:
      newOutput = command + ": command not found\n";
      break;
  }
  return newOutput;
};

const CommandHandlers = {
  handleCommand,
};
export default CommandHandlers;
