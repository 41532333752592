const genLoginTime = () => {
  const date = new Date();
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options).replaceAll(",", "");
};

const genDisplayDir = (currentDir) => {
  if (currentDir) {
    const dirParts = currentDir.split("/");
    if (dirParts.length > 1 && dirParts[0] !== "") {
      return dirParts[dirParts.length - 1];
    } else {
      return currentDir;
    }
  }
};

const buildPrefix = (username, serverName, dir) => {
  return "[" + username + "@" + serverName + " " + dir + "]# ";
};

const Helpers = {
  genLoginTime,
  genDisplayDir,
  buildPrefix,
};

export default Helpers;
