import React, { useState, useEffect, useRef } from "react";
import "./Shell.css";

import KeyHandlers from "./components/KeyHandlers";
import FileSystem from "./components/FileSystem";
import ResumeBuilder from "./components/ResumeBuilder";
import Helpers from "./components/Helpers";
import SuggestionsHandlers from "./components/SuggestionsHandlers";

const Shell = () => {
  const [currentCommand, setCurrentCommand] = useState("");
  const [currentDir, setCurrentDir] = useState("/");
  const [commandHistory, setCommandHistory] = useState([]);
  const [commandHistoryIndex, setCommandHistoryIndex] = useState(-1);
  const [output, setOutput] = useState("");
  const [loginTime, setLoginTime] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [tabPressed, setTabPressed] = useState(false);
  const [dirToDisplay, setDirToDisplay] = useState("/");
  const [version, setVerison] = useState("Joellab 23.04.09");
  const [caretPosition, setCaretPosition] = useState(0);
  const commandsList = ["cd", "ls", "cat", "clear", "help", "sh", "pwd"];
  const [fileSystem, setFileSystem] = useState(FileSystem.initialFileSystemState);
  const [username, setUsername] = useState("user");
  const [serverName, setServerName] = useState("joellab.com");
  const stateRef = useRef();
  const [caretPixelPosition, setCaretPixelPosition] = useState(0);
  const [firstSuggestion, setFirstSuggestion] = useState(0);
  const [suffixTips, setSuffixTips] = useState();
  const [showNonMobilePopup, setShowNonMobilePopup] = useState(false);
  const [showMobileWarningPopup, setShowMobileWarningPopup] = useState(false);

  useEffect(() => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobileDevice) {
      setShowMobileWarningPopup(true);
    } else {
      setShowNonMobilePopup(true);
    }
  }, []);


  //Determine Suggestion Length
  const handleInputChange = (event) => {
    setCurrentCommand(event.target.value);

    setCaretPosition(event.target.selectionStart);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Source Code Pro";
    const inputText = event.target.value.slice(0, event.target.selectionStart);
    const textWidth = Math.ceil(context.measureText(inputText).width);
    const prefixText = Helpers.buildPrefix(username, serverName, dirToDisplay);
    const prefixWidth = Math.ceil(context.measureText(prefixText).width);
    setCaretPixelPosition(prefixWidth + textWidth);
  };

  useEffect(() => {
    stateRef.current = {
      currentCommand,
      commandHistory,
      currentDir,
      fileSystem,
      commandHistoryIndex,
      output,
      dirToDisplay,
      commandsList,
      setCurrentDir,
      setCurrentCommand,
      setCommandHistory,
      setCommandHistoryIndex,
      setOutput,
      setSuggestions,
      setTabPressed,
      username,
      serverName,
      setFileSystem,
      suggestions,
      setSuffixTips,
    };
  }, [
    currentCommand,
    commandHistory,
    currentDir,
    fileSystem,
    commandHistoryIndex,
    output,
    dirToDisplay,
    commandsList,
    setOutput,
    setCurrentDir,
    setCurrentCommand,
    setCommandHistory,
    setCommandHistoryIndex,
    setSuggestions,
    setTabPressed,
    username,
    serverName,
    setFileSystem,
    suggestions,
    setSuffixTips,
  ]);

  //Init
  useEffect(() => {
    const keyDownHandler = KeyHandlers.handleKeyDown(stateRef);
    setLoginTime(Helpers.genLoginTime());
    FileSystem.traverseFileSystem("/", fileSystem["/"], fileSystem);

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    const welcomeTip =
      "\"Hi, I'm Jiawei (Joel), currently a CS student at the University of California San Diego.\nFeel free to explore. Enjoy :-)\"";

    setOutput(
      `Welcome to ${version} LTS (GNU/Linux x86_64)<br /><br />You have a new mail<br /><pre>${welcomeTip} </pre><br /><br /><p>Last login: ${loginTime} </p><span class="info">${version} <br />Based on Unix Shell<br />Run 'help' to get help</span><br /><br />`
    );
  }, [loginTime, version]);

  useEffect(() => {
    //根据浏览器模式自动设置主题
    const setColors = () => {
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.setAttribute("data-theme", "dark");
      } else {
        //document.documentElement.setAttribute("data-theme", "light");
        document.documentElement.setAttribute("data-theme", "dark");
      }
    };
    setColors();
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", setColors);
    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", setColors);
    };
  }, []);

  useEffect(() => {
    //当command变化的时候更新推荐
    !tabPressed
      ? SuggestionsHandlers.updateSuggestions(currentCommand, commandsList, fileSystem, currentDir, setSuggestions)
      : setTabPressed(false);
  }, [currentCommand, tabPressed]);

  useEffect(() => {
    let parts = currentCommand.split(" ");
    //当suggestion变化的时候更新推荐
    if (suggestions.length > 0 && !tabPressed && currentCommand.length > 0) {

      parts = parts.map(part => {
        if (part.startsWith("./")) {
          return part.slice(2);
        } else if (part.startsWith("/")) {
          return part.slice(1);
        }
        return part;
      });

      if (parts.length === 1) {
        setFirstSuggestion(suggestions[0]?.slice(parts[0].length));
      } else {
        setFirstSuggestion(suggestions[0]?.slice(parts[parts.length - 1].length));
      }
    } else {
      setFirstSuggestion("");
    }
    //console.log(suggestions)
  }, [suggestions]);

  useEffect(() => {
    //设置要展示的目录
    setDirToDisplay(Helpers.genDisplayDir(currentDir));
  }, [currentDir]);

  return (
    <div id="main">
      {output && (
        <>
          <div className="content">
            <pre style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: output }}></pre>
          </div>
        </>
      )}
      <div className="input-lable" style={{ marginTop: output ? "-0.4rem" : 0 }}>
        {Helpers.buildPrefix(username, serverName, dirToDisplay)}
        <input
          type="text"
          className="input-text"
          value={currentCommand}
          onChange={(e) => handleInputChange(e)}
          //onKeyDown={KeyHandlers.handleKeyDown}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
        />
        <span
          id="ac-tip"
          style={{
            color: "gray",
            left: `${caretPixelPosition}px`,
            position: "absolute",
          }}
        >
          {firstSuggestion}
        </span>
      </div>
      <pre>{suffixTips}</pre>
      <div className="con-lost">
        <div>
          <span className="con-lost-text">Session Closed</span>
          <br />
          <span className="retry">Enter to Reconnect</span>
        </div>
      </div>
      {showMobileWarningPopup && (
        <div className="overlay">
          <div className="popup">
            <h2>Notice</h2>
            <p>Using Shell on a mobile device may not provide the best experience. Do you want to go directly to the blog?</p>
            <button onClick={() => window.location.href = "https://jwjoel.com"}>Yes</button>
            <button onClick={() => setShowMobileWarningPopup(false)}>No</button>
          </div>
        </div>
      )}

      {showNonMobilePopup && (
        <div className="non-mobile-popup">
          <p>Using Shell requires a certain level of programming knowledge.<br></br> For non-coders, I recommend going directly to the blog.<br></br></p>
          <button onClick={() => window.location.href = "https://jwjoel.com"}>Go to Blog</button>
          <button onClick={() => setShowNonMobilePopup(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Shell;
