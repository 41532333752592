// 定义一个处理自动完成建议的模块
const updateSuggestions = (inputValue, commandsList, fileSystem, currentDir, setSuggestions) => {
  // 定义一个内部函数用于查找字符串数组中的最长公共前缀
  const findLongestCommonPrefix = (strArr) => {
    let commonPrefix = strArr[0];
    for (const candidate of strArr) {
      let i = 0;
      while (i < Math.min(commonPrefix.length, candidate.length) && commonPrefix[i] === candidate[i]) {
        i++;
      }
      commonPrefix = commonPrefix.slice(0, i);
    }
    return commonPrefix;
  };

  // 定义一个内部函数用于获取当前目录的内容
  const getCurrentDirectoryContent = (currentDir, fileSystem) => {
    const dirs = currentDir.split("/").filter((dir) => dir !== "");
    let currentDirectory = fileSystem["/"].content;
  
    for (const dir of dirs) {
      if (currentDirectory.hasOwnProperty(dir)) {
        currentDirectory = currentDirectory[dir].content;
      } else {
        return null;
      }
    }
    return currentDirectory;
  };  

  // 检查输入的命令是否存在
  let commandExists = commandsList.some((command) => inputValue.trim().startsWith(command));
  let commandAndPath = inputValue.split(" ");
  /*
  // 如果输入包含 cd 命令和路径分隔符 /
  if (commandAndPath[0].trim() === "cd") {
    const inputPath = commandAndPath.slice(1).join(" ").trim();
    let splitDir = inputPath.split(/(\/|\s)/);
  
    splitDir = splitDir.map((splitDirPart, index) => {
        if (index % 2 === 0) {
          let updatedCurrentDir = currentDir;
          if (splitDirPart === "..") {
            // 如果遇到 ".."，则返回上一级目录
            const lastSlashIndex = updatedCurrentDir.lastIndexOf("/");
            updatedCurrentDir = updatedCurrentDir.slice(0, lastSlashIndex);
          } else if (splitDirPart !== "." && splitDirPart !== "") {
            // 如果不是 "."（表示当前目录）且不为空，则更新 currentDir
            if (inputPath.startsWith("/")) {
              // 如果输入路径是绝对路径
              updatedCurrentDir = "/" + splitDirPart;
            } else {
              // 如果输入路径是相对路径
              updatedCurrentDir = updatedCurrentDir + (updatedCurrentDir === "/" ? "" : "/") + splitDirPart;
            }
          }
      
          // 检查更新后的 currentDir 是否有效
          if (fileSystem.hasOwnProperty(updatedCurrentDir)) {
            currentDir = updatedCurrentDir;
          } else {
            const dirs = updatedCurrentDir.split("/").filter((dir) => dir !== "");
            let currentDirectory = fileSystem["/"].content;
      
            let validPath = true;
            for (const dir of dirs) {
              if (currentDirectory.hasOwnProperty(dir)) {
                currentDirectory = currentDirectory[dir].content;
              } else {
                validPath = false;
                break;
              }
            }
      
            if (validPath) {
              currentDir = updatedCurrentDir;
            }
          }
        }
      });
    }
    if(currentDir.startsWith("/") && currentDir.length > 1) {
        currentDir = currentDir.slice(1)
    }
    */
  console.log(currentDir)

  const suggestionDir = currentDir; //currentDir is like / -> folder -> folder/folder
  const currentDirectory = getCurrentDirectoryContent(suggestionDir, fileSystem);
  const fileList = currentDirectory
    ? Object.keys(currentDirectory).map((key) => (currentDirectory[key].type === "directory" ? key + "/" : key))
    : [];

  let suggestions = [];

  let specialOverride = false;

  commandAndPath = commandAndPath.map((commandAndPath) => {
    if (commandAndPath.startsWith("./")) {
      specialOverride = true;
      return commandAndPath.slice(2);
    } else if (commandAndPath.startsWith("/")) {
      specialOverride = true;
      return commandAndPath.slice(1);
    }
    return commandAndPath;
  });

  if (specialOverride) {
    inputValue = commandAndPath[commandAndPath.length - 1].trim();
    suggestions = fileList.filter((file) => file.startsWith(inputValue));
  } else if (commandExists && commandAndPath.length > 1) {
    inputValue = commandAndPath[commandAndPath.length - 1].trim();
    suggestions = fileList.filter((file) => file.startsWith(inputValue));
  } else {
    const combinedList = [...commandsList, ...fileList];
    suggestions = combinedList.filter((item) => item.startsWith(inputValue));
  }

  // 处理并设置自动完成建议
  if (suggestions.length > 0) {
    const commonPrefix = findLongestCommonPrefix(suggestions);
    setSuggestions(suggestions.map((suggestion) => inputValue + suggestion.slice(inputValue.length)));
    return commonPrefix.slice(inputValue.length);
  } else {
    setSuggestions([]);
    return "";
  }
};

// 将updateSuggestions函数导出为一个对象的属性
const SuggestionsHandlers = {
  updateSuggestions,
};
export default SuggestionsHandlers;
