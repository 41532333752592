import logo from "./logo.svg";
import "./App.css";
import Shell from "./pages/Shell/Shell";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          <Routes>
            <Route path="/" element={<Shell />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
