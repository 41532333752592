import React, { useState, useEffect } from "react";
import ResumeBuilder from "./ResumeBuilder";
import Helpers from "./Helpers";

const initialFileSystemState = {
  "/": {
    type: "directory",
    content: {
      "resume": {
        type: "directory",
        content: {
          "README.md": { type: "file", operation: "rewrite", content: "get_resume" },
          "linkedin.sh": { type: "script", operation: "redirect", content: "https://www.linkedin.com/in/jwjoel/" },
          projects: {
            type: "directory",
            content: {
              "github.sh": { type: "script", operation: "redirect", content: "https://github.com/jwjoel" },
            },
          },
          publications: {
            type: "directory",
            content: {
              "google_scholar.sh": { type: "script", operation: "redirect", content: "https://scholar.google.co.uk/citations?user=ACOI_3UAAAAJ&hl=en" },
            },
          },
        },
      },
      "blog": {
        type: "directory",
        content: {
          "README.md": { type: "file", operation: "none", content: "Nothing yet" },
          "blog.sh": { type: "script", operation: "redirect", content: "https://jwjoel.com/" },
        },
      },
      "contact": {
        type: "directory",
        content: {
          "README.md": { type: "file", operation: "none", content: "# Contact me\n\n- Email: joel.bradley.w@gmail.com" },
          "send_me_email.sh": { type: "script", operation: "redirect", content: "mailto: joel.bradley.w@gmail.com" },
          "leave_a_message.sh": { type: "script", operation: "none", content: "# Contact me\n\n- Email: joel.bradley.w@gmail.com" },
        },
      },
      "README.md": { type: "file", operation: "none", content: "I like to call this website a 'Web System in the making'. It's like a work-in-progress art piece, constantly evolving and improving. Have any suggestions to help make this masterpiece even better? Let me know!" },
      ".playground": {
        type: "directory",
        content: {
          "get_ip.sh": { type: "script", operation: "get_request", content: "https://api.ipify.org/?format=json" },
          "chat.sh": { type: "script", operation: "function", content: "" },
        },
      },
    },
  },
};

const traverseFileSystem = async (currentPath, node, originalFileSystem) => {
  const queue = [{ path: currentPath, node }];

  while (queue.length > 0) {
    const current = queue.shift();
    const currentPath = current.path;
    const currentNode = current.node;

    if (currentNode.type === "directory") {
      for (const [key, value] of Object.entries(currentNode.content)) {
        let newPath = currentPath === "/" ? "" : currentPath;
        queue.push({ path: newPath + "/" + key, node: value });
      }
    } else if (currentNode.type === "file") {
      if (currentNode.operation === "rewrite") {
        if (currentNode.content === "get_resume") {
          const profile = await ResumeBuilder.fetchLinkedInProfile("/assets/linkedin.html");
          updateFileContent(currentPath, profile, originalFileSystem);
        }
        if (currentNode.content === "get_time") {
          updateFileContent(currentPath, Helpers.genLoginTime(), originalFileSystem);
        }
        //console.log("script detected", currentNode.content, currentPath);
      }
    }
  }
};

const updateFileContent = (path, newContent, fileSystem) => {
  const parts = path.split("/");
  let currentNode = fileSystem["/"];

  for (let part of parts) {
    if (part === "") continue;
    if (currentNode.content && currentNode.content.hasOwnProperty(part)) {
      currentNode = currentNode.content[part];
    } else {
      console.error(`Path not found: ${path}`);
      return;
    }
  }

  if (currentNode.type === "file") {
    currentNode.content = newContent;
    //console.log(`Updated content of file at path: ${path}`);
  } else {
    console.error(`The node at the given path is not a file: ${path}`);
  }
};

const getFileContent = (path, fileSystem) => {
  let parts = path.split("/");
  let currentNode = fileSystem["/"];
  for (let part of parts) {
    if (part === "") continue;
    if (currentNode.content && currentNode.content.hasOwnProperty(part)) {
      currentNode = currentNode.content[part];
    } else {
      return null;
    }
  }
  return currentNode;
};

const parsePath = (inputPath, basePath) => {

  let pathParts = inputPath.split("/");
  let basePathParts = basePath.split("/");

  if (inputPath.startsWith("/")) {
    basePathParts = pathParts;
  } else {
    basePathParts = basePathParts.concat(pathParts.filter((part) => part !== ""));
    //console.log(basePathParts);
  }

  let newPathParts = [];
  basePathParts.forEach((part) => {
    if (part === "." || part === "") {
    } else if (part === "..") {
      newPathParts.pop();
    } else {
      newPathParts.push(part);
    }
  });


  let newPath = newPathParts.join("/");
  newPath = newPath.replace(/\/{2,}/g, "/");
  newPath = newPath || "/";

  return newPath;
};

const FileSystem = {
  initialFileSystemState,
  parsePath,
  getFileContent,
  traverseFileSystem,
};
export default FileSystem;
