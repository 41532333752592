import CommandHandlers from "./CommandHandlers";
import SuggestionsHandlers from "./SuggestionsHandlers";
import Helpers from "./Helpers";

const handleEnter = (
  currentCommand,
  commandHistory,
  currentDir,
  fileSystem,
  setCurrentDir,
  setCurrentCommand,
  setCommandHistory,
  setCommandHistoryIndex,
  commandHistoryIndex,
  setOutput,
  output,
  dirToDisplay,
  username,
  serverName,
  setSuffixTips
) => {
  if (currentCommand.trim() === "") {
    setOutput(output + Helpers.buildPrefix(username, serverName, dirToDisplay) + currentCommand + "\n");
    return;
  }

  let commandArgs = currentCommand.split(" ");
  const command = commandArgs[0];
  commandArgs = commandArgs.slice(1);
  let newOutput = CommandHandlers.handleCommand(command, commandArgs, currentDir, fileSystem, setCurrentDir);
  let newCommandHistory = commandHistory.slice();
  newCommandHistory.push(currentCommand);
  //console.log(newCommandHistory)
  setSuffixTips("");
  setCurrentCommand("");
  setCommandHistory(newCommandHistory);
  setCommandHistoryIndex(commandHistoryIndex);

  if (command === "clear") {
    setOutput("");
  } else {
    setOutput(output + Helpers.buildPrefix(username, serverName, dirToDisplay) + currentCommand + "\n" + newOutput);
  }
};

const handleTab = (
  currentCommand,
  setCurrentCommand,
  currentDir,
  fileSystem,
  commandsList,
  setSuggestions,
  setTabPressed,
  suggestions,
  setSuffixTips
) => {
  const autoCompleteAddon = SuggestionsHandlers.updateSuggestions(
    currentCommand,
    commandsList,
    fileSystem,
    currentDir,
    setSuggestions
  );
  if (autoCompleteAddon !== "") {
    setCurrentCommand(currentCommand + autoCompleteAddon);
    setTabPressed(true);
  } else {
    if (suggestions.length > 1) {
      // 获取suggestions中最长字符串的长度
      const maxLength = Math.max(...suggestions.map((suggestion) => suggestion.length));
      // 为每个建议添加空格，使它们的长度相等，并在末尾添加三个额外的空格作为间隔
      const paddedSuggestions = suggestions.map((suggestion) =>
        suggestion.padEnd(maxLength + 3, " ")
      );
      console.log(paddedSuggestions);
      setSuffixTips(paddedSuggestions);
    }
    
  }
};

const handleArrowUp = (setCurrentCommand, commandHistory, commandHistoryIndex, setCommandHistoryIndex) => {
  if (commandHistory.length > 0 && commandHistoryIndex < commandHistory.length - 1) {
    const newIndex = commandHistoryIndex + 1;
    const newCommand = commandHistory[commandHistory.length - 1 - newIndex];
    setCurrentCommand(newCommand);
    setCommandHistoryIndex(newIndex);
  }
};

const handleArrowDown = (commandHistory, commandHistoryIndex, setCurrentCommand, setCommandHistoryIndex) => {
  if (commandHistoryIndex > -1) {
    const newIndex = commandHistoryIndex - 1;
    const newCommand = newIndex === -1 ? "" : commandHistory[commandHistory.length - 1 - newIndex];
    setCurrentCommand(newCommand);
    setCommandHistoryIndex(newIndex);
  }
};

const handleKeyDown = (stateRef) => {
  return (event) => {
    event.stopPropagation();
    const {
      currentCommand,
      commandHistory,
      currentDir,
      fileSystem,
      setCurrentDir,
      setCurrentCommand,
      setCommandHistory,
      setCommandHistoryIndex,
      commandHistoryIndex,
      setOutput,
      output,
      dirToDisplay,
      commandsList,
      setSuggestions,
      setTabPressed,
      username,
      serverName,
      suggestions,
      setSuffixTips,
    } = stateRef.current;

    const { key } = event;
    if (key === "Enter") {
      event.preventDefault();
      handleEnter(
        currentCommand,
        commandHistory,
        currentDir,
        fileSystem,
        setCurrentDir,
        setCurrentCommand,
        setCommandHistory,
        setCommandHistoryIndex,
        commandHistoryIndex,
        setOutput,
        output,
        dirToDisplay,
        username,
        serverName,
        setSuffixTips
      );
    } else if (key === "Tab") {
      event.preventDefault();
      handleTab(
        currentCommand,
        setCurrentCommand,
        currentDir,
        fileSystem,
        commandsList,
        setSuggestions,
        setTabPressed,
        suggestions,
        setSuffixTips
      );
    } else if (key === "ArrowUp") {
      event.preventDefault();
      handleArrowUp(setCurrentCommand, commandHistory, commandHistoryIndex, setCommandHistoryIndex);
    } else if (key === "ArrowDown") {
      event.preventDefault();
      handleArrowDown(commandHistory, commandHistoryIndex, setCurrentCommand, setCommandHistoryIndex);
    }
    if (key !== "Tab") {
      setTabPressed(false);
    }
  };
};

const KeyHandlers = {
  handleKeyDown,
};
export default KeyHandlers;
